<template>
  <section class="pa-3">
    <modal-loading
      :is-loading="saving || loading"
      :message="saving ? '更新中...' : '読み込み中...'"
      :total-number="processTotalNumber"
      :process-number="processingNumber"
    />
    <h2 class="mb-10">CSV一括更新</h2>

    <section
      v-if="csvUploadArea"
      class="bulk__upload_area col-7 mb-4"
      @dragleave.prevent
      @dragover.prevent
      @drop.prevent="handleSelectedCSV"
    >
      <p class="mb-5">更新したい CSVファイル をここにドロップ</p>
      <p class="mb-6">または</p>
      <v-btn rounded outlined @click="selectFile"> CSVファイルを選択 </v-btn>
      <input ref="csv" type="file" @change="handleSelectedCSV" />
    </section>

    <section
      v-if="photosUploadArea && errorMessages.length === 0"
      class="bulk__upload_area col-7 mb-4"
      @dragleave.prevent
      @dragover.prevent
      @drop.prevent="handleSelectedPhotos"
    >
      <p class="mb-5">更新したい 画像ファイル（複数可）をここにドロップ</p>
      <p class="mb-6">または</p>
      <v-btn rounded outlined @click="selectFile">
        画像ファイル（複数可）を選択
      </v-btn>
      <input ref="photos" type="file" multiple @change="handleSelectedPhotos" />
    </section>

    <router-link :to="{ name: 'csv_download' }" class="mb-5">
      写真情報一覧のCSVはこちらからダウンロードいただけます
    </router-link>

    <section class="template_example">
      <h3 class="template_example__title">画像ファイルの差し替え方法</h3>
      <ol class="template_example__list">
        <li>
          差し替えたい画像のファイル名を「写真ID.拡張子」にリネームします<br />例：写真IDが1、拡張子がjpgの場合、ファイル名を「1.jpg」とする
        </li>
        <li>1のファイル名を「ファイル名」の列に入力します</li>
        <li>この画面でCSVファイルを選択します</li>
        <li>続けて画像ファイルを選択します</li>
        <li>「更新」ボタンを押します</li>
      </ol>
    </section>

    <section class="mb-4">
      <div v-if="!csvUploadArea && photoDataList !== 0" class="subtitle-1 mb-2">
        全 {{ photoDataList.length }} 件
      </div>
      <v-simple-table v-if="!csvUploadArea">
        <thead>
          <tr>
            <!-- <th><v-checkbox /></th> -->
            <th />
            <!-- 画像 -->
            <th>写真ID</th>
            <th>タイトル</th>
            <th>撮影場所</th>
            <th>撮影日時</th>
            <th>ステータス</th>
            <th>カテゴリ</th>
          </tr>
        </thead>
        <tbody v-if="photoDataList !== 0">
          <tr v-for="(data, index) in photoDataList" :key="index">
            <!-- <td class="pa-2"><v-checkbox /></td> -->
            <td class="pa-2">
              <v-img
                v-show="data.preview"
                :src="data.preview"
                width="48px"
                min-height="48px"
                max-height="48px"
              />
            </td>
            <td>
              {{ data.id }}
            </td>
            <td>
              {{ toHyphen(data.title) }}
            </td>
            <td>
              {{ toHyphen(data.place) }}
            </td>
            <td>
              {{ shot_at(data.shot_at) }}
            </td>
            <td>
              {{ status(data.status) }}
            </td>
            <td>
              {{ data.categories }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </section>

    <div>
      <v-alert v-if="!loading && errorMessages.length !== 0" text color="error">
        <div v-for="(message, i) in errorMessages" :key="i" class="py-1">
          {{ message }}
        </div>
      </v-alert>
    </div>

    <div>
      <v-alert
        v-if="!loading && warningMessages.length !== 0"
        text
        color="warning"
      >
        <div v-for="(message, i) in warningMessages" :key="i" class="py-1">
          {{ message }}
        </div>
      </v-alert>
    </div>

    <v-col v-if="photoDataList.length !== 0">
      <v-row justify="space-between">
        <v-btn v-if="photoDataList.length !== 0" outlined large @click="cancel">
          キャンセル
        </v-btn>
        <v-btn
          v-if="!csvUploadArea && errorMessages.length === 0"
          color="primary"
          large
          @click="update"
        >
          更新
        </v-btn>
      </v-row>
    </v-col>
  </section>
</template>

<script>
import Encoding from "encoding-japanese";
import Papa from "papaparse";
import loadImage from "blueimp-load-image";
import { DateTime } from "luxon";
import { getData, createData, updateData } from "../../axios";
import ModalLoading from "../../components/ModalLoading.vue";

export default {
  name: "BulkUpdate",

  components: {
    ModalLoading,
  },

  data() {
    return {
      saving: false,
      loading: false,
      processTotalNumber: 0,
      processingNumber: 0,
      csvUploadArea: true,
      photosUploadArea: false,
      photoDataList: [],
      tags: [],
      nonexistentTags: [],
      categories: [],
      errorMessages: [],
      warningMessages: [],
      japaneseItems: {
        title: "タイトル",
        description: "写真の説明",
        memo: "メモ",
        photographer: "撮影者",
        owner: "権利者",
        place: "撮影場所",
        latitude: "緯度",
        longitude: "経度",
        enable_location_opendata: "緯度と経度をオープンデータとして公開する",
        shot_at: "撮影日時",
        license: "ライセンス",
        license_description: "ライセンス自由入力",
        file_name: "ファイル名",
        status: "ステータス",
        tags: "キーワード",
        categories: "カテゴリ",
        id: "写真ID",
      },
      required: ["title", "license", "status", "id"],
      license: [
        "cc-by",
        "cc-by-sa",
        "cc-by-nd",
        "cc-by-nc",
        "cc-by-nc-sa",
        "cc-by-nc-nd",
        "cc0",
        "all-rights-reserved",
        "other",
        "unknown",
      ],
    };
  },

  mounted() {
    this.getTags();
    this.getCategories();
  },

  methods: {
    getTags() {
      getData("tags").then((res) => {
        this.tags = res.data;
      });
    },
    getCategories() {
      getData("categories").then((res) => {
        this.categories = res.data;
      });
    },
    // 共通整備項目で、経度、緯度は小数点以下6桁となっているため、統一する
    round(num) {
      return Math.floor(num * 1000000) / 1000000;
    },
    selectFile() {
      if (this.csvUploadArea) {
        this.$refs.csv.click();
      } else if (this.photosUploadArea) {
        this.$refs.photos.click();
      }
    },
    handleSelectedCSV(event) {
      this.loading = true;
      this.csvUploadArea = false;
      this.errorMessages = [];
      const selectedCSV = event.target.files || event.dataTransfer.files;
      const type = selectedCSV[0].name.split(".");
      if (type[type.length - 1].toLowerCase() === "csv") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const codes = new Uint8Array(e.target.result);
          const encoding = Encoding.detect(codes);
          const unicodeString = Encoding.convert(codes, {
            to: "unicode",
            from: encoding,
            type: "string",
          });
          Papa.parse(unicodeString, {
            skipEmptyLines: true,
            complete: (result) => {
              // csvの見出し以外を取得
              const csv = result.data.splice(1, result.data.length - 1);
              csv.forEach((line) => {
                const jsDate = new Date(line[11]);
                const date = DateTime.fromJSDate(jsDate).isValid
                  ? DateTime.fromJSDate(jsDate).toFormat("yyyy-MM-dd HH:mm:ss")
                  : line[11];

                this.photoDataList.push({
                  title: line[0],
                  description: line[2],
                  memo: line[3],
                  place: line[4],
                  photographer: line[5],
                  owner: line[6],
                  tags: line[7],
                  latitude: line[8] ? this.round(Number(line[8])) : "",
                  longitude: line[9] ? this.round(Number(line[9])) : "",
                  enable_location_opendata: line[10] ? line[10] : 0,
                  location_accuracy: "unknown",
                  shot_at: date,
                  shot_at_accuracy: "unknown",
                  license: line[12],
                  license_description: line[13],
                  image: "",
                  image_width: line[15],
                  image_height: line[16],
                  preview: "",
                  file_name: line[14],
                  id: line[17],
                  status: line[18],
                  categories: line[19],
                });
              });

              this.validate();
              this.loading = false;
              this.photosUploadArea = true;
            },
          });
        };
        try {
          reader.readAsArrayBuffer(selectedCSV[0]);
        } catch {
          this.errorMessages.push(
            "CSVファイル読み込みに失敗しました。もう一度やり直してください。"
          );
          this.loading = false;
          this.csvUploadArea = true;
          return;
        }
      } else {
        this.errorMessages.push("CSVファイルを選択してください。");
        this.loading = false;
        this.csvUploadArea = true;
        return;
      }
    },
    handleSelectedPhotos(event) {
      this.loading = true;
      this.photosUploadArea = false;
      this.errorMessages = [];
      const uploadedPhotos = event.target.files || event.dataTransfer.files;
      // FileListはforEach出来ない為
      const photosPromises = Array.from(uploadedPhotos).map((photo) => {
        return new Promise((resolve) => {
          loadImage(photo, {
            maxHeight: 100,
            maxWidth: 100,
            canvas: true,
          })
            .then((data) => {
              const preview = data.image.toDataURL();
              const width = data.originalWidth;
              const height = data.originalHeight;
              resolve({
                name: photo.name,
                image: photo,
                width,
                height,
                preview,
              });
            })
            .catch(() => {
              this.warningMessages.push(
                `「${photo.name}」は写真の読み込みに失敗したため、登録されません。`
              );
              resolve({
                name: photo.name,
                image: "",
                width: "",
                height: "",
                preview: "",
              });
            });
        });
      });
      const extensionToLowercase = (str) => {
        const a = str.split(".");
        a[a.length - 1] = a[a.length - 1].toLowerCase();
        return a.join(".");
      };
      Promise.all(photosPromises)
        .then((uploadedPhotos) => {
          this.photoDataList.forEach((photoData, index) => {
            uploadedPhotos.some((photo) => {
              if (
                extensionToLowercase(photoData.file_name) ===
                extensionToLowercase(photo.name)
              ) {
                this.photoDataList[index].image = photo.image;
                this.photoDataList[index].image_width = photo.width;
                this.photoDataList[index].image_height = photo.height;
                this.photoDataList[index].preview = photo.preview;
                return true;
              } else {
                return false;
              }
            });
          });
          this.loading = false;
        })
        .catch((err) => {
          this.errorMessages.push(err);
          this.photosUploadArea = true;
          this.loading = false;
        });
    },
    validate() {
      this.errorMessages = [];
      Object.keys(this.photoDataList).forEach((i) => {
        const index = Number(i);
        Object.keys(this.photoDataList[index]).forEach((key) => {
          this.checkRequired(index, key, this.required);
          this.checkMaxLength(index, key, 100, [
            "title",
            "photographer",
            "owner",
            "license_description",
          ]);
          this.checkMaxLength(index, key, 1000, [
            "description",
            "memo",
            "place",
          ]);
          this.checkNumber(index, key, ["latitude", "longitude"]);
          this.checkDate(index, key);
          this.checkSelected(index, key, ["license"]);
          this.checkBoolean(index, key, ["enable_location_opendata"]);
          this.checkExistCategories(index, key);
        });
      });
    },
    checkRequired(index, key, requiredList) {
      if (
        requiredList.indexOf(key) !== -1 &&
        this.photoDataList[index][key].length === 0
      ) {
        this.errorMessages.push(
          `${this.photoDataList[index].id}：${this.japaneseItems[key]}は必須項目です。`
        );
      }
    },
    checkSelected(index, key, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          this[item].indexOf(this.photoDataList[index][key]) === -1
        ) {
          const select = this[item].join("、");
          this.errorMessages.push(
            `${this.photoDataList[index].id}：${this.japaneseItems[key]}は「${select}」から選択してください。`
          );
        }
      });
    },
    checkMaxLength(index, key, max, targets) {
      targets.forEach((item) => {
        if (key === item && max < this.photoDataList[index][key].length) {
          this.errorMessages.push(
            `${this.photoDataList[index].id}：${this.japaneseItems[key]}は${max}文字以下で記入してください。`
          );
        }
      });
    },
    checkNumber(index, key, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          !Number.isFinite(Number(this.photoDataList[index][key])) &&
          this.photoDataList[index][key] !== ""
        ) {
          this.errorMessages.push(
            `${this.photoDataList[index].id}：${this.japaneseItems[key]}は半角数字で記入してください。`
          );
        }
      });
    },
    checkBoolean(index, key, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          !(
            Number(this.photoDataList[index][key]) === 0 ||
            Number(this.photoDataList[index][key]) === 1
          ) &&
          this.photoDataList[index][key] !== ""
        ) {
          this.errorMessages.push(
            `${this.photoDataList[index].id}：${this.japaneseItems[key]}は半角数字で0か1を記入してください。`
          );
        }
      });
    },
    checkDate(index, key) {
      if (
        key === "shot_at" &&
        this.photoDataList[index][key] &&
        !DateTime.fromSQL(this.photoDataList[index][key]).isValid
      ) {
        this.errorMessages.push(
          `${this.photoDataList[index].id}：${this.japaneseItems[key]}が不正な値です。`
        );
      }
    },
    checkExistCategories(index, key) {
      if (key === "categories") {
        const items = this.photoDataList[index].categories.split(/\||｜/);
        items.forEach((item) => {
          const category = item.trim();
          const exist = this.categories.some((v) => v.name === category);
          if (!exist && category) {
            this.errorMessages.push(
              `${index + 1}行目：${
                this.japaneseItems[key]
              }の「${category}」は存在しません。あらかじめカテゴリメニューから登録してください。`
            );
          }
        });
      }
    },
    cancel() {
      this.csvUploadArea = true;
      this.photosUploadArea = false;
      this.photoDataList = [];
      this.errorMessages = [];
      this.warningMessages = [];
    },
    update() {
      this.saving = true;
      this.processTotalNumber = this.photoDataList.length;
      const formDataList = [];

      // タグの存在確認
      Object.keys(this.photoDataList).forEach((key) => {
        this.splitTags(this.photoDataList[key].tags);
        this.splitCategories(this.photoDataList[key].categories);
      });
      new Promise((resolve) => {
        // 存在しないタグがあればタグの保存
        if (this.nonexistentTags.length > 0) {
          createData("tags/bulk", { tags: this.nonexistentTags }).then(
            (res) => {
              this.tags = res.data;
              this.nonexistentTags = [];
              resolve(true);
            }
          );
        } else {
          resolve(true);
        }
      })
        .then(() => {
          Object.keys(this.photoDataList).forEach((key) => {
            formDataList.push(this.handleFormData(this.photoDataList[key]));
          });

          const saveCount = formDataList.length;
          let isPosting = false;
          this.processingNumber = 0;
          let timer = setInterval(() => {
            if (!isPosting && formDataList.length !== 0) {
              isPosting = true;
              const formData = formDataList.shift();
              if (typeof formData.get("image") === "string") {
                // 写真の編集をしていない時は'string'となっておりAPIのバリデーションに引っかかる為送らない
                formData.delete("image");
              }
              if (typeof formData.get("video") === "string") {
                // 動画の編集をしていない時は'string'となっておりAPIのバリデーションに引っかかる為送らない
                formData.delete("video");
              }
              updateData(`photos/${formData.get("id")}`, formData)
                .then(() => {
                  isPosting = false;
                  this.processingNumber++;
                })
                .catch(() => {
                  clearInterval(timer);
                });
            } else if (!isPosting && formDataList.length === 0) {
              clearInterval(timer);
              this.saving = false;
              this.$store.dispatch("snackbar/setSnackbar", {
                message: `${saveCount}件のデータを更新しました。`,
                color: "success",
                timeout: 2000,
              });
              this.$router.push("/photos");
            }
          }, 1000);
        })
        .catch(() => {
          this.saving = false;
          this.$store.dispatch("snackbar/setSnackbar", {
            message: `更新に失敗しました。もう一度やり直してください。`,
            color: "error",
            timeout: 5000,
          });
        });
    },
    handleFormData(photoData) {
      const formData = new FormData();
      Object.keys(photoData).forEach((key) => {
        if (key === "preview") {
          return;
        } else if (key === "tags") {
          if (!(photoData.tags && photoData[key])) {
            return
          }
          this.splitTags(photoData[key]).forEach((tag) => {
            if (tag) {
              formData.append(key + "[]", tag);
            }
          });
        } else if (key === "categories") {
          if (!(photoData.categories && photoData[key])) {
            return;
          }
          this.splitCategories(photoData[key]).forEach((category) => {
            if (category) {
              formData.append(key + "[]", category);
            }
          });
        } else if (key === "shot_at") {
          const shotAt = DateTime.fromSQL(photoData[key]).isValid
            ? DateTime.fromSQL(photoData[key])
            : null;
          if (shotAt === null) {
            return;
          }
          formData.append(key, shotAt.toISO())
        } else if ((key === "image_width" || key === "image_height") && photoData[key] === "") {
          return;
        } else {
          formData.append(key, photoData[key]);
        }
      });

      return formData;
    },
    splitTags(strTags) {
      const tags = strTags.split(/\||｜/);
      return tags.map((tag) => {
        tag = tag.trim();
        const target = this.tags.find((data) => tag === data.label);
        if (target) {
          return target.id;
        } else {
          if (tag) {
            this.nonexistentTags.push(tag);
          }
          return null;
        }
      });
    },
    splitCategories(strCategories) {
      const categories = strCategories.split(/\||｜/);
      return categories.map((category) => {
        category = category.trim();
        const target = this.categories.find((data) => category === data.name);
        if (target) {
          return target.id;
        } else {
          return null;
        }
      });
    },
    shot_at(value) {
      if (!value) {
        return "ー";
      } else {
        return DateTime.fromSQL(value).isValid
          ? DateTime.fromSQL(value).toFormat("yyyy.MM.dd - HH:mm")
          : value;
      }
    },
    status(value) {
      return value === "unpublished" ? "下書き" : "公開";
    },
    toHyphen(value) {
      return value || "ー";
    },
  },
};
</script>

<style scoped>
.bulk__upload_area {
  width: 300px;
  height: 300px;
  padding: 4rem 1rem;
  text-align: center;
  border: 0.1rem dotted #bcbcbc;
}

.bulk__upload_area input {
  display: none;
}

.template_example {
  margin: 20px 0;
  padding: 16px;
  border: 1px solid #bcbcbc;
}

.template_example__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.template_example__list li {
  font-size: 14px;
  margin-bottom: 8px;
}

.template_example__list span {
  font-weight: bold;
}

.note {
  margin: 6px 0 0;
}

.required {
  font-style: normal;
  color: red;
}
</style>
